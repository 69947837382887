import React, { useState, useEffect, useRef } from 'react';

import {
    CHECKBOX_STATUS,
    constants,
    MultiSelect,
    Select
} from '@armis/armis-ui-library';
import { FormHelperText, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import {
    ADMIN,
    ALL,
    OPTIONAL,
    REQUIRED,
    SELECT_PARTNERS,
    SELECT_ROLES,
    TOTAL_SELECTED
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    validateValues,
    isFieldHavingError,
    convertQueryObjectToParams,
    updateSelectedItems
} from 'src/helpers/utility';
import { EditField } from 'src/pages/components/UserModalContainer/EditField/EditField';
import {
    OptionalSpanStyle,
    StyledDiv,
    StyledSpan
} from 'src/pages/components/UserModalContainer/EditField/EditField.style';
import {
    ACTION_TYPE,
    PROPERTY_FIELD_KEY_MAP,
    UserFieldMetaData
} from 'src/pages/containers/UserManagement/UserConstants';
import { getPartnersData, getRoles, getUserDetails } from 'src/services/api.service';
import { PropertyValidationType, FilterItems } from 'src/types/CommonTypes';

const AddUserModalContainer = ({
    usersData,
    items,
    onUserAdd,
    customError,
    onAddPartners,
    onAddRoles,
    setIsLoading,
    actionType,
    selectedRole
}: {
    usersData: any;
    items: string[];
    onUserAdd: any;
    customError: any;
    onAddPartners: (value: string[]) => void;
    onAddRoles: (value: string[]) => void;
    setIsLoading: any;
    actionType: string;
    selectedRole: string[];
}) => {
    const [roles, setRoles] = useState<FilterItems[]>([]);
    const [partners, setPartners] = useState<FilterItems[]>([]);
    const [user, setUser] = useState(usersData);
    const [userErrors, setUserErrors] = useState<any>(customError);
    const [selectedPartners, setSelectedPartners] = useState<string[]>([]);
    const [rolesCount, setRolesCount] = useState([SELECT_ROLES]);
    const [partnersCount, setPartnersCount] = useState([SELECT_PARTNERS]);
    const firstRender = useRef(true);
    const isAdminRoleSelected = !!selectedRole.find(role =>
        roles.find(uRole => uRole.id === role && uRole.label === ADMIN)
    );

    useEffect(() => {
        onUserAdd(user);
    }, [user]);

    useEffect(() => {
        const doApiCall = async () => {
            if (firstRender.current)
                try {
                    setIsLoading(true);
                    const getRole = await getRoles('');
                    const getTotalRoles = getRole.data.map((item: any) => ({
                        label: item.name,
                        id: item.id,
                        checkStatus: CHECKBOX_STATUS.UNCHECKED,
                        labelRender: <span>{item.name}</span>
                    }));
                    setRoles(getTotalRoles);

                    const getPartners = await getPartnersData(
                        convertQueryObjectToParams({ isPaged: false })
                    );
                    const getPartnersList = getPartners.data.content.map(
                        (item: any) => ({
                            label: item.name,
                            id: item.id,
                            checkStatus: CHECKBOX_STATUS.UNCHECKED,
                            labelRender: <span>{item.name}</span>
                        })
                    );

                    setPartners(getPartnersList);
                    updateSelectedItems(
                        selectedPartners.map((item: any) => item),
                        getPartnersList,
                        setPartnersCount,
                        setPartners,
                        onAddPartners,
                        SELECT_PARTNERS,
                        ALL,
                        TOTAL_SELECTED
                    );

                    if (actionType === ACTION_TYPE.EDIT) {
                        const userDetails = await getUserDetails(
                            items.toString()
                        );
                        const userDetailsData = {
                            firstname: userDetails.data.firstname,
                            lastname: userDetails.data.lastname,
                            username: userDetails.data.username,
                            phonenumber: userDetails.data.phonenumber
                        };
                        setUser(userDetailsData);
                        updateSelectedItems(
                            userDetails.data.roles.map((item: any) => item.id),
                            getTotalRoles,
                            setRolesCount,
                            setRoles,
                            onAddRoles,
                            SELECT_ROLES,
                            ALL,
                            TOTAL_SELECTED
                        );
                        updateSelectedItems(
                            userDetails.data.partners.map(
                                (item: any) => item.id
                            ),
                            getPartnersList,
                            setPartnersCount,
                            setPartners,
                            onAddPartners,
                            SELECT_PARTNERS,
                            ALL,
                            TOTAL_SELECTED
                        );
                    }
                } catch (err: any) {
                    displayErrorMessage(err);
                } finally {
                    setIsLoading(false);
                }
        };
        doApiCall();
        firstRender.current = false;
    }, []);

    useEffect(() => {
        setUserErrors(customError);
    }, [customError]);

    const getValue = (name: string) => {
        const currentValue = user[name];
        return currentValue || '';
    };
    const fieldOnChangeHandler = (
        name: string,
        value: string,
        type: string,
        validations: PropertyValidationType
    ) => {
        let fieldValidationErrorArray = {};
        setUser((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        const validationErrorObject = validateValues(value, validations, type);
        if (validationErrorObject.error) {
            fieldValidationErrorArray = {
                ...userErrors,
                [name]: {
                    ...validationErrorObject
                }
            };
        }
        if (Object.keys(fieldValidationErrorArray).length > 0) {
            setUserErrors(fieldValidationErrorArray);
        } else {
            const usersCopy = cloneDeep(userErrors);
            delete usersCopy[name];
            setUserErrors(usersCopy);
        }
    };
    const getErrorHelperText = (field: string) =>
        userErrors?.[field]?.helperText ?? '';

    return (
        <Grid columnSpacing={{ xs: 1, sm: 2, md: 3 }} container item>
            <>
                {Object.keys(UserFieldMetaData).map(
                    field =>
                        field !== PROPERTY_FIELD_KEY_MAP.ROLES && (
                            <EditField
                                key={UserFieldMetaData[field].key}
                                disable={
                                    !!(
                                        UserFieldMetaData[field].key ===
                                            PROPERTY_FIELD_KEY_MAP.USERNAME &&
                                        actionType === ACTION_TYPE.EDIT
                                    )
                                }
                                error={isFieldHavingError(field, userErrors)}
                                gridSize={UserFieldMetaData[field].gridSize}
                                helperText={getErrorHelperText(field)}
                                id={UserFieldMetaData[field].key}
                                label={UserFieldMetaData[field].label}
                                onChange={value => {
                                    fieldOnChangeHandler(
                                        UserFieldMetaData[field].key,
                                        value,
                                        UserFieldMetaData[field].type,
                                        UserFieldMetaData[field].validations
                                    );
                                }}
                                optional={UserFieldMetaData[field].optional}
                                type={UserFieldMetaData[field].type}
                                value={getValue(UserFieldMetaData[field].key)}
                            />
                        )
                )}
                <Grid item xs={12}>
                    <StyledDiv>
                        <StyledSpan>Roles</StyledSpan>
                        <Select
                            defaultValue={SELECT_ROLES}
                            MenuProps={{
                                sx: { zIndex: 10002 },
                                PaperProps: {
                                    sx: {
                                        minWidth: '500px !important'
                                    }
                                },
                                MenuListProps: {
                                    sx: {
                                        '.multiselect-filtered-div': {
                                            maxHeight: '194px'
                                        }
                                    }
                                }
                            }}
                            renderValue={() => rolesCount}
                            sx={{
                                border: isFieldHavingError(
                                    PROPERTY_FIELD_KEY_MAP.ROLES,
                                    userErrors
                                )
                                    ? `1px solid ${constants.COLOR_5}`
                                    : ''
                            }}
                            value={rolesCount}
                            variant="outlined"
                        >
                            <MultiSelect
                                isAllSelected={false}
                                items={roles}
                                onSelectionChanged={selectedItems => {
                                    updateSelectedItems(
                                        selectedItems.map(
                                            element => element.id
                                        ),
                                        roles,
                                        setRolesCount,
                                        setRoles,
                                        onAddRoles,
                                        SELECT_ROLES,
                                        ALL,
                                        TOTAL_SELECTED
                                    );
                                    if (selectedItems.length === 0) {
                                        let fieldValidationErrorArray = {};
                                        fieldValidationErrorArray = {
                                            ...userErrors,
                                            roles: {
                                                error: true,
                                                helperText: REQUIRED
                                            }
                                        };
                                        setUserErrors(
                                            fieldValidationErrorArray
                                        );
                                    } else {
                                        const usersCopy = cloneDeep(userErrors);
                                        delete usersCopy[
                                            PROPERTY_FIELD_KEY_MAP.ROLES
                                        ];
                                        setUserErrors(usersCopy);
                                    }
                                }}
                                showSelectAllOption
                            />
                        </Select>
                        {isFieldHavingError(
                            PROPERTY_FIELD_KEY_MAP.ROLES,
                            userErrors
                        ) && (
                            <FormHelperText error>
                                {getErrorHelperText(
                                    PROPERTY_FIELD_KEY_MAP.ROLES
                                )}
                            </FormHelperText>
                        )}
                    </StyledDiv>

                    {!isAdminRoleSelected && (
                        <StyledDiv>
                            <StyledSpan>
                                By Partners{' '}
                                <OptionalSpanStyle>
                                    {OPTIONAL}
                                </OptionalSpanStyle>
                            </StyledSpan>
                            <Select
                                defaultValue={SELECT_PARTNERS}
                                MenuProps={{
                                    sx: { zIndex: 10002 },
                                    PaperProps: {
                                        sx: {
                                            minWidth: '500px !important'
                                        }
                                    },
                                    MenuListProps: {
                                        sx: {
                                            '.multiselect-filtered-div': {
                                                maxHeight: '194px'
                                            }
                                        }
                                    }
                                }}
                                renderValue={() => partnersCount}
                                value={partnersCount}
                                variant="outlined"
                            >
                                <MultiSelect
                                    isAllSelected={false}
                                    items={partners}
                                    onSelectionChanged={selectedItems => {
                                        const selectedPartnersItems =
                                            selectedItems.map(
                                                element => element.id
                                            );
                                        setSelectedPartners(
                                            selectedPartnersItems
                                        );
                                        updateSelectedItems(
                                            selectedPartnersItems,
                                            partners,
                                            setPartnersCount,
                                            setPartners,
                                            onAddPartners,
                                            SELECT_PARTNERS,
                                            ALL,
                                            TOTAL_SELECTED
                                        );
                                    }}
                                    showSelectAllOption
                                />
                            </Select>
                        </StyledDiv>
                    )}
                </Grid>
            </>
        </Grid>
    );
};

export default AddUserModalContainer;
