import React, { useEffect, useRef, useState } from 'react';

import { CHECKBOX_STATUS, MultiSelect, Select } from '@armis/armis-ui-library';
import { Grid } from '@mui/material';
import { AxiosResponse, AxiosError } from 'axios';
import {
    ALL,
    ASSIGN_USERS_LABEL,
    SELECT_USERS,
    TOTAL_SELECTED
} from 'src/constants/LabelText';
import { displayErrorMessage, updateSelectedItems } from 'src/helpers/utility';
import {
    StyledDiv,
    StyledSpan
} from 'src/pages/components/UserModalContainer/EditField/EditField.style';
import { getPartnerWithUsers } from 'src/services/api.service';
import {
    ErrorResponse,
    PartnersType,
    PartnersTypeWithUser
} from 'src/types/APIResponseTypes';
import { FilterItems } from 'src/types/CommonTypes';

interface AssignUserType {
    currentMenuItem: PartnersType | null;
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
    onUserSelected: (value: string[]) => void;
}

const AssignUser = ({
    currentMenuItem,
    setIsLoading,
    onUserSelected
}: AssignUserType) => {
    const [users, setUsers] = useState<FilterItems[]>([]);
    const [userCount, setUserCount] = useState([SELECT_USERS]);
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            setIsLoading(true);
            getPartnerWithUsers(currentMenuItem?.id as string)
                .then((res: AxiosResponse<PartnersTypeWithUser>) => {
                    if (res.data.users.length > 0) {
                        const getUsers = res.data.users.map((item: any) => ({
                            label: `${item.firstname} ${item.lastname}`,
                            id: item.id,
                            checkStatus:
                                item.isPartnerAssigned || item.isAdmin
                                    ? CHECKBOX_STATUS.CHECKED
                                    : CHECKBOX_STATUS.UNCHECKED,
                            labelRender: (
                                <span>
                                    {item.firstname} {item.lastname}
                                </span>
                            ),
                            disabled: item.isAdmin
                        }));
                        setUsers(getUsers);
                        const usersList = res.data.users
                            .filter(
                                (item: any) =>
                                    item.isPartnerAssigned || item.isAdmin
                            )
                            .map(item => item.id);
                        updateSelectedItems(
                            usersList,
                            getUsers,
                            setUserCount,
                            setUsers,
                            onUserSelected,
                            SELECT_USERS,
                            ALL,
                            TOTAL_SELECTED
                        );
                    }
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        firstRender.current = false;
    }, []);

    return (
        <Grid columnSpacing={{ xs: 1, sm: 2, md: 3 }} container item>
            <Grid item xs={12}>
                <StyledDiv>
                    <StyledSpan>{ASSIGN_USERS_LABEL}</StyledSpan>
                    <Select
                        defaultValue={SELECT_USERS}
                        MenuProps={{
                            sx: { zIndex: 10002 },
                            PaperProps: {
                                sx: {
                                    minWidth: '500px !important'
                                }
                            },
                            MenuListProps: {
                                sx: {
                                    '.multiselect-filtered-div': {
                                        maxHeight: '194px'
                                    }
                                }
                            }
                        }}
                        renderValue={() => userCount}
                        value={userCount}
                        variant="outlined"
                    >
                        <MultiSelect
                            isAllSelected={false}
                            items={users}
                            onSelectionChanged={selectedItems => {
                                const selectedUsersList = selectedItems.map(
                                    element => element.id
                                );
                                updateSelectedItems(
                                    selectedUsersList,
                                    users,
                                    setUserCount,
                                    setUsers,
                                    onUserSelected,
                                    SELECT_USERS,
                                    ALL,
                                    TOTAL_SELECTED
                                );
                            }}
                            showSelectAllOption
                        />
                    </Select>
                </StyledDiv>
            </Grid>
        </Grid>
    );
};

export default AssignUser;
